let baseSize = 16
function setRem() {
    const salepro = document.documentElement.clientWidth / 750
    if (window.innerWidth <= 768) {
        baseSize = 40
    } else {
        baseSize = 16
    }
    // console.log('baseSize', baseSize)
    document.documentElement.style.fontSize = (baseSize * Math.min(salepro, 2)) + 'px'
}
setRem()
window.onresize = function () {
    setRem()
}
